<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：总览 > <b>系统通知</b></span>
            <!--<div class="flex-center"><i class="iconfont iconjiantou-copy-copy"></i>返回</div>-->
        </div>
        <div class="send-info">
            <div class="send-condition flex-center">
                <div class="send-condition-item flex-center">
                    <span>审核类型:</span>
                    <el-select v-model="type" placeholder="请选择" @change="classifyChange($event)">
                        <el-option
                                v-for="item in typeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <el-checkbox v-model="is_read" class="checked-chiose"><span>仅未读</span></el-checkbox>
                <a href="javascript:void 0" @click="toRead('')">全部标记为已读</a>
            </div>
            <div class="send-list">
                <div class="system-list">
                    <div class="system-item " v-for="(item) in list" :key="item.id"
                         :class="{active:item.is_read ===0,red:item.notice_show === 2}" @click="toDetail(item)">
                        <div class="ovHide">{{ item.title }}</div>
                        <el-button size="mini" type="primary" @click.stop="toFinancialBook(item)">进入台账</el-button>
                        <span>{{ item.add_time }}</span>
                    </div>
                </div>
                <el-pagination
                        class="page-all"
                        background
                        layout="prev, pager, next"
                        :page-size="limit"
                        :total="pageTotal"
                        :current-page.sync="page"
                        @current-change="setPage">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import {toDetail} from "../../tools";

    export default {
        name: "send",
        data() {
            return {
                typeOptions: this.$tools.approvalClassify(),
                type: '',
                list: [],
                page: 1,
                pageTotal: 1,
                limit: 9,
                is_read: false,
                timer: '',
                financiaBook:false
            }
        },
        computed: {
            user() {
                return this.$store.state.user;
            },
        },
        created() {
            this.$emit('setIndex', [0, 4])
            let that = this
            this.timer = setInterval(function () {
                //执行内容
                if (that.page === 1) {
                    that.init()
                }
            }, 3000);
            this.financiaBook = this.user.jid === 2 || this.user.jid === 3 || this.user.did === 4
        },
        beforeDestroy() {
            clearInterval(this.timer);
        },
        methods: {
            toDetail(row) {

                let type = row.role === 1 ? 1 : row.role === 2 ? 0 : -1
                //1我发起的 2  我审批的 0 抄送我的
                 toDetail(row,type)
                this.toRead(row.id)
            },
            toFinancialBook(row) {
                this.$router.push('/home/financialBook?oid='+row.pid)
            },
            toRead(id) {
                this.$api.apiContent.toRead({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: id,
                    sign: this.$tools.SHA256({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: id,
                    }, this.$store.state.user.key)
                }).then(() => {
//                    this.counts = res.data
                    this.init()
                }).catch(() => {
//                    this.counts = {}
                })
            },
            classifyChange(event) {
                this.type = event
                this.page = 1
                this.init()
            },
            // 分页变化
            setPage(val) {
                this.page = val
                this.init()
            },
            init() {
                this.$api.apiContent.getNotice({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    page: this.page,
                    limit: this.limit,
                    type: this.type ? this.type : 0,
                    is_read: this.is_read ? 2 : 0,
                    sign: this.$tools.SHA256({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        page: this.page,
                        limit: this.limit,
                        type: this.type ? this.type : 0,
                        is_read: this.is_read ? 2 : 0,
                    }, this.$store.state.user.key)
                }).then(res => {
                    res.data.list.map(item => {
                        item.add_time = this.$tools.ZHDate(new Date(item.add_time * 1000), 1)
                    })
                    this.list = res.data.list
                    this.pageTotal = res.data.count
                }).catch(() => {
                    this.list = []
                    this.pageTotal = 0
                })
            }
        },
        watch: {
            is_read() {
                this.page = 1
                this.init()
            }
        }
    }
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }

            }
        }

        .send-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            overflow: hidden;
            min-height: 750px;

            .send-condition {
                padding: 24px 28px 16px;
                border-bottom: 2px solid #f5f5f5;

                .send-condition-item {
                    margin-right: 20px;

                    span {
                        margin-right: 10px;
                    }
                }

                .checked-chiose {
                    margin: 0 20px;

                    span {
                        font-size: 14px;
                    }
                }

                a {
                    color: #999;
                    font-size: 14px;
                }

            }

            .send-list {
                padding: 34px 28px;
                min-height: 600px;

                .system-list {
                    .system-item {
                        display: flex;
                        align-items: center;
                        height: 61px;
                        line-height: 61px;
                        padding: 0 140px 0 25px;
                        border-bottom: 1px solid #f4f4f4;
                        position: relative;
                        font-size: 18px;
                        &:hover {
                            cursor: pointer;
                        }
                        &::before {
                            position: absolute;
                            top: 25px;
                            left: 5px;
                            content: '';
                            width: 10px;
                            height: 10px;
                            border-radius: 100%;
                            background: #dddddd;
                        }

                        &.red {
                            color: red;
                        }
                        &.active {
                            &::before {
                                background: #3b77e7;
                            }
                        }

                        span {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 140px;
                            line-height: 60px;
                            text-align: right;
                            font-size: 16px;
                            color: #999999;
                        }
                    }
                }

                .page-all {
                    text-align: center;
                    padding: 30px 0 0;
                }
            }
        }
    }

</style>